<template>
    <div class='account_add_edit zc_dialog_box' v-if="dialogVisible">
        <el-dialog :visible.sync="dialogVisible" width="534px" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="inv_main">
                <div class="main_text">
                    <div class="left"><span>*</span>评论</div>
                    <div class="right">
                        <el-input v-model="text" type="textarea" :rows="3" placeholder="请输入评论">
                        </el-input>
                    </div>
                </div>
                <div class="main_text">
                    <div class="left">图片</div>
                    <div class="right">
                        <div class="upload">
                            <div class="image" v-for="(item,index) in images" :key="index">
                                <div class="mask"></div>
                                <i class="el-icon-close" @click="closeImg(index)"></i>
                                <img :src="item" alt="">
                            </div>
                            <el-upload v-if="images.length<9" ref="upload" class="r_img bg11 b_r5 c_p" action="" :accept="imgAccept" multiple :show-file-list="false" :http-request="httpRequestMultiple" :before-upload="beforeAvatarUpload" :limit="9">
                                <div class="r_img_no w100 h100 t-align box_b">
                                    <i class="el-icon-plus f_s5 color7"></i>
                                    <div class="f_s1 color7">上传图片</div>
                                </div>
                            </el-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" style="padding: 0 20px;">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        type: Number
    },
    data() {
        return {
            imgAccept: 'image/jpg,image/jpeg,image/png,image/gif',
            imgTip: '图片：大小≤3MB，建议“正方形”图片（即1:1的宽高），上限9张，图片最大宽高为750*750，只能上传jpg、jpeg、png格式文件。',
            dialogVisible: false,
            id: '',
            comid: '',
            btnLoading: false,
            text: "",
            images: [],
        }
    },
    computed: {
        title() {
            return this.type == 1 ? '添加评论' : '编辑评论'
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            // 文章id
            this.id = id
            this.dialogVisible = true
        },
        // 编辑打开弹窗
        openBtn(info) {
            // 文章id
            this.id = info.flow_id
            this.comid = info.id
            this.images = info.img ? info.img : []
            this.text = info.content
            this.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            this.text = "";
            this.images = []
            this.dialogVisible = false
            this.clearFiles()
        },
        // 确定
        affirmBtn() {
            var that = this
            if (that.text == '') {
                that.$errMsg('请输入评论内容')
                return
            } else if (that.type == 1) {
                // 添加
                that.btnLoading = true
                var params = {
                    flow_id: that.id,
                    content: that.text,
                    img: that.images.toString()
                }
                that.$flowApi.commentAdd(params).then(res => {
                    that.btnLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.dialogVisible = false
                        that.clearFiles()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.type == 2) {
                // 编辑
                that.btnLoading = true
                var params = {
                    flow_id: that.id,
                    content: that.text,
                    img: that.images.toString()
                }
                that.$flowApi.commentEdit(params, that.comid).then(res => {
                    that.btnLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('submit')
                        that.dialogVisible = false
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        closeImg(index) {
            this.images.splice(index, 1)
        },
        httpRequestMultiple(uploader) {
            const formData = new FormData();
            formData.set('file', uploader.file);
            this.$publicApi.uploadFile(formData).then(res => {
                if (res.code === 1000) {
                    if (this.images.length < 9) {
                        this.images.push(res.result.url)
                        uploader.onSuccess();
                    } else {
                        // this.$message.error('最多上传9张图片');
                        return
                    }
                } else {
                    this.$message.error(res.message);
                }
            })
        },

        beforeAvatarUpload(file, index) {
            this.index = index
            const isLt10KB = file.size / 1024 < 3 * 1024;
            let types = this.imgAccept.split(',');
            const isType = types.includes(file.type);
            if (!isType) {
                this.$message.error('只能上传只' + this.imgAccept + '格式!');
            }
            if (!isLt10KB) {
                this.$message.error('上传图片大小不能超过 3M!');
            }

            return isType && isLt10KB;
        },

        clearFiles() {
            this.$refs.upload.clearFiles();
        }
    },
}
</script>

<style lang='scss' scoped>
.account_add_edit {
    .inv_main {
        width: 100%;
        padding: 10px 20px 0 0;

        .main_text {
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;

            .left {
                width: 80px;
                line-height: 40px;
                text-align: right;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;

                span {
                    color: #e00c25;
                }
            }

            .right {
                width: 380px;

                .el-input {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                }
                .el-cascader {
                    width: 100%;
                }
                .right_txt {
                    padding-top: 10px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ff2b2b;
                }
                .upload {
                    width: 380px;
                    display: flex;
                    flex-wrap: wrap;

                    .r_img {
                        width: 80px;
                        height: 80px;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        background-color: #f5f7fa;
                        border-radius: 4px;

                        .r_img_no {
                            padding-top: 20px;
                            i {
                                font-size: 20px;
                            }

                            div {
                                padding-top: 5px;
                            }
                        }
                    }

                    .image {
                        width: 80px;
                        height: 80px;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        position: relative;
                        cursor: pointer;

                        img {
                            width: 80px;
                            height: 80px;
                            border-radius: 4px;
                        }

                        .mask {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            background: rgba(0, 0, 0, 0.3);
                            display: none;
                        }

                        .el-icon-close {
                            position: absolute;
                            right: 3px;
                            top: 3px;
                            display: none;
                            color: #ffffff;
                            font-size: 20px;
                            z-index: 100;
                        }
                    }

                    .image:hover {
                        .el-icon-close {
                            display: block;
                        }

                        .mask {
                            display: block;
                        }
                    }

                    .r_txt {
                        padding-left: 10px;
                    }

                    .el-select {
                        width: 100%;
                    }
                }
            }
        }
        .main_text:last-child {
            margin-bottom: 0;
        }
    }
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}

::v-deep .el-switch {
    margin-top: 8px;
}
::v-deep .el-upload {
    width: 100%;
}
</style>
