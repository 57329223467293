<template>
    <div class='account_manage'>
        <!-- 标题 -->
        <div class="vue_title">流量圈列表</div>
        <div class="account_main">
            <!--  -->
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addEditBtn()" size="small" icon="el-icon-plus">新增</el-button>
                    <el-input v-model="title" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="请输入标题"></el-input>
                </div>
                <!-- <div class="cond_right">
                    <el-button type="primary" class="btnBgColor_grey" size="small" icon="el-icon-upload2" @click='exportBtn'>导出</el-button>
                </div> -->
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" ref="multipleTable" @selection-change="handleSelectionChange" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <!-- <el-table-column type="selection" align="center" width="55"></el-table-column> -->
                    <el-table-column prop="id" label="ID" align="center" width="60"></el-table-column>
                    <el-table-column prop="user_name" label="用户名" show-overflow-tooltip width="180">
                        <template slot-scope="scope">
                            <div class="user">
                                <img class="img" :src="scope.row.user_cover" alt="">
                                <p>{{scope.row.user_name}}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_position" label="发布者职位" width="120"></el-table-column>
                    <el-table-column prop="cont" label="发布内容" width="300" show-overflow-tooltip></el-table-column>
                    <el-table-column label="发布图片" width="210">
                        <template slot-scope="scope">
                            <div class="imgbox" v-if="scope.row.cover.length>0">
                                <el-image class="img" style="width:55px; height: 55px" v-for="item in scope.row.cover" :key="item" :src="item" :preview-src-list="srcList">
                                </el-image>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="发布时间"></el-table-column>
                    <el-table-column prop="like_count" label="点赞数">
                        <template slot-scope="scope">
                            <p>{{scope.row.like_count<0?0:scope.row.like_count}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="transmit_count" label="转发数"></el-table-column>
                    <el-table-column prop="comment_count" label="评论数"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="220">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="addEditBtn(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="delBtn(scope.row.id)">删除</el-button>
                            <el-button type="text" size="small" @click="toComment(scope.row.id)">查看评论</el-button>
                            <el-button type="text" size="small" @click="comment(scope.row.id)">发布评论</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="accountDel" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">删除该文章后将不在当前列表显示，确定要删除吗？</div>
            </div>
        </point-out>
        <!-- 发布评论 -->
        <release-comment ref="dialog" :type="type" @submit='submitBtn'></release-comment>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import ReleaseComment from '../components/release_comment/index.vue'//评论
export default {
    components: { PointOut, EmptyData, PagingPage, ReleaseComment },
    props: {},
    data() {
        return {
            title: '',
            list: [],
            loading: false,
            ids: [],
            multipleSelection: [],
            //
            page: 1,
            limit: 10,
            total: 0,
            srcList: [],
            // 删除
            del_id: '',
            type: 1,
        }
    },
    computed: {},
    watch: {},
    created() {
        this.search()
    },
    mounted() { },
    methods: {
        search() {
            this.page = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        // 发布评论
        comment(id) {
            this.$refs.dialog.openDialogBtn(id)
        },
        // 列表
        getList() {
            var that = this
            that.loading = true
            var params = {
                title: that.title,
                page: that.page,
                limit: that.limit,
            }
            that.$flowApi.flowList(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result?.data
                    that.total = res.result?.total
                    that.total = res.result?.total
                    let arr = []
                    that.list.forEach(ele => {
                        ele.cont = this.removeHTMLTag(ele.title)
                        ele.cover.forEach(item => {
                            arr.push(item)
                        })
                    })
                    this.srcList = arr
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 富文本去掉标签样式
        removeHTMLTag(str) {
            str = str.replace(/<\/?[^>]*>/g, ""); //去除HTML tag
            str = str.replace(/[ | ]*\n/g, "\n"); //去除行尾空白
            str = str.replace(/\n[\s| | ]*\r/g, "\n"); //去除多余空行
            str = str.replace(/&nbsp;/gi, ""); //去掉&nbsp;
            return str;
        },
        // 表单选择
        handleSelectionChange(val) {
            this.ids = []
            val.forEach(item => {
                this.ids.push(item.id)
            })
            this.multipleSelection = val
        },
        // 评论列表
        toComment(id) {
            this.$router.push({
                name: 'flow_comment',
                query: {
                    id: id
                }
            })
        },
        // 新增/编辑
        addEditBtn(id) {
            if (id) {
                this.$router.push({
                    name: 'flow_add',
                    query: {
                        id: id
                    }
                })
            } else {
                this.$router.push('/flow/add')
            }
        },
        // 删除
        delBtn(id) {
            this.del_id = id
            this.$refs.dialogTip.openDialogBtn()
        },
        closeListBtn() {
            var that = this
            if (that.del_id !== '') {
                that.$flowApi.flowDel(this.del_id).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.getList()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 新增/编辑=确定
        submitBtn() {
            this.getList()
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 导出
        exportBtn() {
            // var that = this
            // var params = {
            //     role_id: that.roleValue,
            //     mobile: that.mobile,
            //     ids: that.ids.join(','),
            // }
            // that.$purviewApi.userExport(params).then(res => {
            //     return
            // })
        },
    },
}
</script>

<style lang='scss' scoped>
.account_manage {
    width: 100%;
    .account_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
            .cond_right {
                .el-button {
                    padding: 8px 10px;
                }
            }
        }
    }
}
.user {
    display: flex;
    align-items: center;
    .img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    p {
        width: 125px;
        font-size: 14px;
        margin-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.imgbox {
    display: flex;
    flex-wrap: wrap;
    .img {
        margin-right: 6px;
        margin-top: 6px;
    }
}
</style>
